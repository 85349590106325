<template>
<div>
    <div id="preloader" class="saas-classic-preloader"></div>
    <div class="up">
        <a href="#" id="scrollup" class="saas-classic-scrollup text-center"><i class="fas fa-angle-up"></i></a>
    </div>
  <header id="header_main" class="saas_two_main_header">
        <div class="container">
            <div class="s_main_menu">
                <div class="row">
                    <div class="col-md-2">
                        <div class="brand_logo">
                            <router-link to="/"><img src="assets/images/logo.png" alt=""></router-link>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="main_menu_list clearfix float-right">
                            <nav class="s2-main-navigation  clearfix ul-li">
                                <ul id="main-nav" class="navbar-nav text-capitalize clearfix">
                                    <li class="side-demo position-relative"><router-link to="/">ACCUEIL</router-link></li>
                                    <!-- <li><router-link to="/about">QUI SOMME NOUS</router-link></li> -->
                                    <li><router-link to="/tarifs">TARIFS</router-link></li>
                                    <li><router-link to="/fonctionnalites">FONCTIONNALITES</router-link></li>
                                    <li><router-link to="/blogs">BLOG</router-link></li>
                                </ul>
                            </nav>
                            <div class="saas_sign_up_btn text-center">
                                <router-link to="/contact">CONTACT</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //desktop menu -->
            <div class="s2-mobile_menu relative-position">
                <div class="s2-mobile_menu_button s2-open_mobile_menu">
                    <i class="fas fa-bars"></i>
                </div>
                <div class="s2-mobile_menu_wrap">
                    <div class="mobile_menu_overlay s2-open_mobile_menu"></div>
                    <div class="s2-mobile_menu_content">
                        <div class="s2-mobile_menu_close s2-open_mobile_menu">
                            <i class="far fa-times-circle"></i>
                        </div>
                        <div class="m-brand-logo text-center">
                            <router-link to="/tarifs"><img src="assets/images/logo.png" alt=""></router-link>
                        </div>
                        <nav class="s2-mobile-main-navigation  clearfix ul-li">
                        <ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
                            <li class="side-demo position-relative"><router-link to="/">ACCUEIL</router-link></li>
                            <li><router-link to="/tarifs">TARIFS</router-link></li>
                            <li><router-link to="/fonctionnalites">FONCTIONNALITES</router-link></li>
                            <li><router-link to="/blogs">BLOG</router-link></li>
                            <li><router-link to="/contact">CONTACTEZ-NOUS</router-link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        </div>
    </header>
</div>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style>

</style>
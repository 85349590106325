
const routes = [
  {
    path: "/",
    component: () => import('@/views/Home.vue'),
    name: "home",
    meta: {
      title : 'Smartsites | Logiciel de gestion commerciale en ligne'
    }
  },
  {
    path: "/about",
    component: () => import('@/views/About.vue'),
    name: "about",
    meta: {
      title : 'Smartsites | Qui somme nous'
    }
  },
  {
    path: "/contact",
    component: () => import('@/views/Contact.vue'),
    name: "contact",
    meta: {
      title : 'Smartsites | Contactez-nous'
    }
  },
  {
    path: "/fonctionnalites",
    component: () => import('@/views/Fonctionnalite.vue'),
    name: "fonctionnalites",
    meta: {
      title : 'Smartsites | Fonctionnalites'
    }
  },
  {
    path: "/tarifs",
    component: () => import('@/views/Tarif.vue'),
    name: "tarifs",
    meta: {
      title : 'Smartsites | Tarifs'
    }
  },
  {
    path: "/blogs",
    component: () => import('@/views/Blog.vue'),
    children: [
      {
        path: ":id",
        component: () => import('@/views/BlogDetail.vue'),
        props: true
      }
    ],
    meta: {
      title : 'Smartsites | Actualités'
    }
  },
  {
    path: "/blogDetail/:id",
    component: () => import('@/views/BlogDetail.vue'),
    props: true
  },
  {
    path: "/C-est-quoi-un-logiciel-CRM",
    component: () => import('@/views/BlogDetail2.vue'),
    props: true,
    meta: {
      title : 'Smartsites | C-est-quoi-un-logiciel-CRM'
    }
  },
  {
    path: "/Comment-il-peut-me-servire-ce-logiciel-CRM",
    component: () => import('@/views/BlogDetail3.vue'),
    props: true,
    meta: {
      title : 'Smartsites | Comment-il-peut-me-servire-ce-logiciel-CRM'
    }
  },
  {
    path: "/Logiciel-crm-pour-gerer-les-factures-et-les-ventes",
    component: () => import('@/views/BlogDetail4.vue'),
    props: true,
    meta: {
      title : 'Smartsites | Logiciel-crm-pour-gerer-les-factures-et-les-ventes'
    }
  },
  {
    path: "/Regelements-des-client-et-fournisseurs",
    component: () => import('@/views/BlogDetail5.vue'),
    props: true,
    meta: {
      title : 'Smartsites | Regelements-des-client-et-fournisseurs'
    }
  },
  {
    path: "/Un-logiciel-CRM-et-statistiques",
    component: () => import('@/views/BlogDetail6.vue'),
    props: true,
    meta: {
      title : 'Smartsites | Un-logiciel-CRM-et-statistiques'
    }
  },
  {
    path: "/Comment-Un-logiciel-CRM-peut-facilité-mon-travail",
    component: () => import('@/views/BlogDetail7.vue'),
    props: true,
    meta: {
      title : 'Smartsites | Comment-Un-logiciel-CRM-peut-facilité-mon-travail'
    }
  },

  {
    path: "*",
    redirect: "/"
  }
]

export default routes
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './routes'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)

/* Vue.prototype.$http = axios
Vue.prototype.$axios = axios
Vue.config.productionTip = false */

const router = new VueRouter({
  mode: "history",
  routes: Routes,
  scrollBehavior(){
    return { x: 0, y: 0}
  }
});

// We add meta title in router list so that we can change the title of the page every time
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
